import"core-js/modules/es.array.find.js";import"core-js/modules/es.object.to-string.js";import sumBy from'lodash/sumBy';import{normalizeChatMessage}from'soapbox/normalizers';import{ChatKeys}from'soapbox/queries/chats';import{queryClient}from'soapbox/queries/client';import{compareDate}from'./comparators';import{appendPageItem,flattenPages,sortQueryData,updatePageItem}from'./queries';/**
 * Update the Chat entity inside the ChatSearch query.
 * @param newChat - Chat entity.
 */var updateChatInChatSearchQuery=function updateChatInChatSearchQuery(newChat){updatePageItem(ChatKeys.chatSearch(),newChat,function(o,n){return o.id===n.id;});};/**
 * Re-order the ChatSearch query by the last message timestamp.
 */var reOrderChatListItems=function reOrderChatListItems(){sortQueryData(ChatKeys.chatSearch(),function(chatA,chatB){var _chatA$last_message,_chatB$last_message;return compareDate((_chatA$last_message=chatA.last_message)===null||_chatA$last_message===void 0?void 0:_chatA$last_message.created_at,(_chatB$last_message=chatB.last_message)===null||_chatB$last_message===void 0?void 0:_chatB$last_message.created_at);});};/**
 * Check if a Chat entity exists within the cached ChatSearch query.
 * @param chatId - String
 * @returns Boolean
 */var checkIfChatExists=function checkIfChatExists(chatId){var currentChats=flattenPages(queryClient.getQueryData(ChatKeys.chatSearch()));return currentChats===null||currentChats===void 0?void 0:currentChats.find(function(chat){return chat.id===chatId;});};/**
 * Force a re-fetch of ChatSearch.
 */var invalidateChatSearchQuery=function invalidateChatSearchQuery(){queryClient.invalidateQueries(ChatKeys.chatSearch());};var updateChatListItem=function updateChatListItem(newChat){var chatId=newChat.id,lastMessage=newChat.last_message;var isChatAlreadyLoaded=checkIfChatExists(chatId);if(isChatAlreadyLoaded){// If the chat exists in the client, let's update it.
updateChatInChatSearchQuery(newChat);// Now that we have the new chat loaded, let's re-sort to put
// the most recent on top.
reOrderChatListItems();}else{// If this is a brand-new chat, let's invalid the queries.
invalidateChatSearchQuery();}if(lastMessage){// Update the Chat Messages query data.
appendPageItem(ChatKeys.chatMessages(newChat.id),normalizeChatMessage(lastMessage));}};/** Get unread chats count. */var getUnreadChatsCount=function getUnreadChatsCount(){var chats=flattenPages(queryClient.getQueryData(ChatKeys.chatSearch()));return sumBy(chats,function(chat){return chat.unread;});};export{updateChatListItem,getUnreadChatsCount,reOrderChatListItems};